import request, { ResponsePageableData } from '@/libs/request'

/** 订餐商品列表 */
export const getGoodsList = (data?) => request<ResponsePageableData>({ url: '/adminapi/RestaurantGood/getRestaurantGoodList', data })

/** 订餐商品新增/编辑 */
export const editGoods = (data?) => request<ResponsePageableData>({ url: '/adminapi/RestaurantGood/editRestaurantGood', data })

/** 订餐商品删除 */
export const delGoods = (data?) => request<ResponsePageableData>({ url: '/adminapi/RestaurantGood/delRestaurantGood', data })
