import request, { ResponsePageableData } from '@/libs/request'

/** 订餐商品分类列表 */
export const getGoodsTypeList = (data?) => request<ResponsePageableData>({ url: '/adminapi/RestaurantGoodType/getRestaurantGoodTypeList', data })

/** 订餐商品分类新增/编辑 */
export const editGoodsType = (data?) => request<ResponsePageableData>({ url: '/adminapi/RestaurantGoodType/editRestaurantGoodType', data })

/** 订餐商品分类删除 */
export const delGoodsType = (data?) => request<ResponsePageableData>({ url: '/adminapi/RestaurantGoodType/delRestaurantGoodType', data })
