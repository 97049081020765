import request, { ResponsePageableData } from '@/libs/request'

/** 订餐商品SKU列表 */
export const getGoodsSKUList = (data?) => request<ResponsePageableData>({ url: '/adminapi/RestaurantGoodSku/getRestaurantGoodSkuList', data })

/** 订餐商品SKU新增/编辑 */
export const editGoodsSKU = (data?) => request<ResponsePageableData>({ url: '/adminapi/RestaurantGoodSku/editRestaurantGoodSku', data })

/** 订餐商品SKU删除 */
export const delGoodsSKU = (data?) => request<ResponsePageableData>({ url: '/adminapi/RestaurantGoodSku/delRestaurantGoodSku', data })
