






















































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getGoodsSKUList, editGoodsSKU, delGoodsSKU } from '@/api/meal_goods_sku'
import SelectHotel from '@/components/select/select-hotel.vue' //酒店选择器
import SelectGoods from '@/components/select/select-meal-goods.vue' //订餐商品选择器
import { cloneDeep } from 'lodash'

export default Vue.extend({
	components: { SelectHotel, SelectGoods },
	data() {
		return {
			editGoodsSKU,
			good_id: undefined,
			...useTable({
				name: '订餐商品SKU',
				formdata: {
					id: 0,
					good_id: '',
					name: '',
					original_price: 0,
					price: 0,
				},
				getData: (query) => getGoodsSKUList({ ...query, good_id: (this as any).good_id }).then((res) => res.data),
				delData: (row) => delGoodsSKU({ id: row.id }),
			}),
		}
	},
	methods: {
		onSubmit(row) {
			let param = row
			param.good_id = this.good_id
			return editGoodsSKU(param).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		edit(row) {
			const params = cloneDeep(row)
			;(this.$refs.formDialogRef as any).open(params)
		},
	},
	created() {
		let id: string = this.$route.query.id as string
		;(this as any).good_id = Number(id) || undefined

		this.tableGetData()
	},
})
